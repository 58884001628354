@use '@angular/material' as mat;

@use 'utils/shared-variables';

/* stylelint-disable */
$philips-typography: mat.m2-define-typography-config(
	$font-family: shared-variables.$primary-font,
);

@include mat.all-component-typographies($philips-typography);
@include mat.elevation-classes();
@include mat.app-background();

$philips-theme-color: (
	50: #ecf2e4,
	100: #cee0bc,
	200: #aecb90,
	300: #8eb664,
	400: #75a642,
	500: #5d9621,
	600: #558e1d,
	700: #4b8318,
	800: #417914,
	900: #30680b,
	A100: #bdff9b,
	A200: #9cff68,
	A400: #7bff35,
	A700: #6aff1b,
	contrast: (
		50: rgba(black, 0.87),
		100: rgba(black, 0.87),
		200: rgba(black, 0.87),
		300: rgba(black, 0.87),
		400: rgba(black, 0.87),
		500: rgba(black, 0.87),
		600: rgba(black, 0.87),
		700: rgba(black, 0.87),
		800: white,
		900: white,
		A100: rgba(black, 0.87),
		A200: rgba(black, 0.87),
		A400: rgba(black, 0.87),
		A700: rgba(black, 0.87),
	),
);

$philips-primary: mat.m2-define-palette($philips-theme-color);
$philips-accent: mat.m2-define-palette($philips-theme-color, A200, A100, A400);

$philips-warn: mat.m2-define-palette(mat.$m2-red-palette);

$philips-theme: mat.m2-define-light-theme(
	(
		color: (
			primary: $philips-primary,
			accent: $philips-accent,
			warn: $philips-warn,
		),
		typography: $philips-typography,
		density: 0,
	)
);

@include mat.all-component-themes($philips-theme);
/* stylelint-enable */
