@use 'utils/colors';
@use 'utils/shared-variables';
@use 'utils/z-indexes';

/* Overrides for new dropdown component */
.select-panel.mat-mdc-select-panel {
	.mat-pseudo-checkbox {
		border-color: colors.$primary-dark-color;
		background-color: transparent;
		margin-right: 16px;
	}
	.mat-pseudo-checkbox.mat-pseudo-checkbox-checked {
		border-color: colors.$primary-dark-color;
		background-color: colors.$primary-dark-color;

		&::after {
			color: colors.$color-light;
		}
	}

	.mat-option-disabled {
		.mat-pseudo-checkbox {
			border-color: colors.$disabled-selected-color;
		}

		.mat-pseudo-checkbox.mat-pseudo-checkbox-checked {
			border-color: colors.$disabled-selected-color;
			background-color: colors.$disabled-selected-color;
		}
	}

	.options.hidden {
		display: none !important;
	}

	.mat-mdc-option {
		--mat-option-focus-state-layer-color: #{colors.$hover-background-color};
		--mat-option-selected-state-layer-color: #{colors.$hover-background-color};
		min-height: shared-variables.$dropdown-option-height;

		&:hover:not(.mdc-list-item-disabled) {
			background-color: colors.$hover-background-color;
		}

		&:not(.mat-mdc-option-multiple) .mat-pseudo-checkbox {
			display: none;
		}

		&.hidden {
			display: none !important;
		}

		.mdc-list-item__primary-text {
			color: colors.$secondary-color;
			letter-spacing: normal;
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
			font: {
				family: shared-variables.$primary-font;
				size: 16px;
			}
		}

		&.mdc-list-item--selected {
			font-weight: bold;

			.mdc-list-item__primary-text {
				color: colors.$primary-dark-color;
			}
		}

		&.mdc-list-item--disabled {
			.mdc-list-item__primary-text {
				color: colors.$disabled-selected-color;
			}

			&.mdc-list-item--selected {
				.mdc-list-item__primary-text {
					color: colors.$disabled-selected-color;
				}
			}
		}
		&.not-found {
			.mdc-list-item__primary-text {
				color: colors.$primary-dark-color;
			}
		}
	}

	&.mdc-menu-surface {
		padding: 0;
	}
}

.mat-mdc-select .mat-mdc-select-trigger {
	font-size: 14px;
	letter-spacing: normal;
	display: flex;
	justify-content: center;

	.mat-mdc-select-arrow-wrapper {
		width: 14px;
		transform: translateY(1px);
	}
}

.nutriu {
	.mat-mdc-optgroup-label {
		font-family: shared-variables.$secondary-font;
		font-size: 12px;
		font-weight: 500;
		line-height: 1.5;
		text-align: left;
		color: colors.$primary-dark-color;
		text-transform: uppercase;
		padding-left: 16px;
		min-height: shared-variables.$dropdown-option-height;

		.mdc-list-item__primary-text {
			padding-left: 0;
		}
	}

	.mat-mdc-optgroup .mat-mdc-option:not(.mat-mdc-option-multiple) {
		padding-left: 16px;
		padding-right: 16px;

		&.indented {
			padding-left: 32px;
		}
	}
}
