/* stylelint-disable */
@use 'utils/shared-variables';
@use 'utils/colors';

$slide-toggle-bar: #b9d29f;

.mat-mdc-slide-toggle {
  label {
		font: {
			family: shared-variables.$secondary-font !important;
			size: 14px !important;
			weight: 500 !important;
		}

		color: colors.$base-green-color;
		line-height: 1.29;
	}

  .mdc-form-field--align-end > label {
    padding-right: 8px;
  }

  .mdc-form-field {
    vertical-align: initial;
  }

  .mdc-switch__handle::before,
  .mdc-switch__handle::after {
		background-color: colors.$base-grey-light-color !important;
  }

  .mdc-switch__track::before,
  .mdc-switch__track::after {
		background-color: colors.$ternary-light-color !important;
	}

  .mdc-switch__icons,
	.mdc-switch__ripple {
		display: none !important;
	}

	&.mat-mdc-slide-toggle-checked {
		.mdc-switch__handle::before,
		.mdc-switch__handle::after {
			background-color: colors.$base-green-color !important;
		}

    .mdc-switch__track::before,
    .mdc-switch__track::after {
			background-color: $slide-toggle-bar !important;
		}
	}
}
