@use 'utils/shared-variables';
@use 'utils/colors';

.nutriu {
	.mat-calendar {
		.mat-calendar-header {
			.mat-calendar-period-button {
				background: transparent;
				border: none;
				color: colors.$base-grey-color;
			}
		}
		.mat-calendar-body-cell-content {
			border-radius: 4px;

			&.mat-calendar-body-selected {
				color: colors.$primary-dark-color;
				background-color: colors.$base-light-green-color;
				font-family: shared-variables.$ternary-font;
			}
		}
	}
}
