@use 'utils/colors';
@use 'utils/shared-variables';

$drag-handler-size: 56px;

.draggable-item {
	.row {
		position: relative;

		.row--drag-handle {
			cursor: move;
			content: '';
			visibility: hidden;
			opacity: 0;
			position: absolute;
			left: calc(-1 * (#{$drag-handler-size} / 2));
			top: 50%;
			margin-top: calc(-1 * (#{$drag-handler-size} / 2));
			width: $drag-handler-size;
			height: $drag-handler-size;
			box-shadow: shared-variables.$box-shadow-small;
			border-radius: 100%;
			transition: opacity 0.3s;
			z-index: 10;
			background: {
				color: colors.$tertiary-background-color;
				image: url('/assets/img/ic-drag.svg');
				repeat: no-repeat;
				size: 12px 28px;
				position: center center;
			}
		}

		&:hover {
			background-color: colors.$section-box-form-color;

			.row--drag-handle {
				opacity: 1;
				visibility: visible;
			}
		}
	}

	.row--drag-handle-disabled {
		visibility: hidden;
		opacity: 0;
	}
}

.row--drag-placeholder {
	background: colors.$placeholder-color;
}

.item-drag-preview-container .item-drag-preview {
	display: block;

	.row {
		border-bottom: none;

		.row--drag-handle {
			opacity: 1;
			visibility: visible;
		}
	}
}

.dropzone-pagination--preview {
	background: colors.$secondary-light-color;
	color: colors.$color-light;
	font-family: shared-variables.$secondary-font;
	padding: 6px 16px;
	border-radius: 3px;
	min-width: 70px;
	text-align: center;
	font-size: 10px;
	font-weight: bold;
	line-height: 12px;
	position: relative;
	margin-left: -35px;
	margin-top: -30px;

	&::after {
		content: '';
		position: absolute;
		top: 100%;
		left: 0;
		right: 0;
		margin: 0 auto;
		width: 0;
		height: 0;
		border-top: solid 5px colors.$secondary-light-color;
		border-left: solid 5px transparent;
		border-right: solid 5px transparent;
	}
}
