@use 'utils/colors';

.link {
	color: colors.$primary-color;
	text-decoration: none;

	&--bold {
		font-weight: bold;
	}

	&:hover {
		text-decoration: underline;
	}
}

.link-dark {
	color: colors.$primary-dark-color;
	text-decoration: none;
	cursor: pointer;

	&:hover {
		text-decoration: underline;
	}
}

.list-row {
	background-color: colors.$color-light;

	&:hover {
		background-color: colors.$hover-background-color;
	}
}

.bar-container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
}

.bar-view-container {
	display: flex;
	height: 100vh;
}

.bar-list {
	flex: 1;
}

@media print {
	.bar-view-container {
		height: 100%;
		overflow-y: visible;
	}
}

.padding {
	padding: 16px;
}
