@use 'sass:color';

$color-light: #FFFFFF;
$color-grey-lightest: #F1F1F1;
$base-grey-lighter-color: #F2F2F2;
$base-grey-light-color: #E5E5E5;
$base-grey-middle-color: #B9B9B9;
$base-grey-color: #3C3C3C;
$base-light-green-color: #EDF1C8;
$base-green-color: #5D9621;
$base-blue-color: #3171D9;
$base-dark-blue-color: #0050A8;
$base-darker-blue-color: #0A2457;
$base-light-blue-color: #EAF0FB;
$base-white-color: #FFFFFF;
$base-orange-color: #F5A623;
$base-orange-dark-color: #E65800;
$base-red-color: #C11400;
$base-red-dark-color: #790F03;
$base-red-light-color: #E5B7B2;
$base-beige-green-color: #F6F8E2;
$base-pale-color: #FCF4DB;
$base-teal-color: #00BCB8;
$background-color-light: $color-grey-lightest;
$background-color-gray: #EFEFEF;
$text-color-lighter: $color-light;
$text-color-error: $base-red-color;
$text-color-warning: $base-grey-color;
$placeholder-color: $base-grey-middle-color;
$primary-background-color: $base-green-color;
$secondary-background-color: $base-grey-lighter-color;
$tertiary-background-color: $base-white-color;
$tertiary-background-dark-color: #FAFAFA;
$light-grey-background-color: $base-grey-light-color;
$lighter-grey-background-color: rgba(239, 239, 239, 25%);
$middle-grey-background-color: $base-grey-middle-color;
$hover-background-color: #F6F8E3;
$warning-background-color: $base-red-color;
$warning-background-dark-color: $base-red-dark-color;
$warning-background-light-color: $base-red-light-color;
$danger-background-light-color: #F7E2E0;
$warning-background-light-color: $danger-background-light-color;
$light-background-color: #F9F9F9;
$category-background-color: #F6F6F6;
$teal-background-color: $base-teal-color;
$primary-light-color: $base-light-green-color;
$primary-color: $base-green-color;
$primary-dark-color: #024732;
$secondary-light-color: #586E6E;
$ternary-light-color: #B9B9B9;
$secondary-color: $base-grey-color;
$middle-grey-text-color: $base-grey-middle-color;
$ternary-color: $base-white-color;
$warning-color: $base-orange-color;
$textarea-color: #113030;
$text-medium-gray-color: #4A4A4A;
$primary-border-color: $base-grey-light-color;
$primary-border-hover-color: $primary-background-color;
$primary-border-color-light: $base-grey-lighter-color;
$transparent-background: transparent;
$button-primary-color: $primary-background-color;
$button-primary-hover-color: color.adjust($primary-background-color, $lightness: -5%);
$button-warning-hover-color: color.adjust($warning-background-color, $lightness: -5%);
$button-unelevated-color: rgba(0, 0, 0, 87%);
$button-unelevated-disabled-color: rgba(0, 0, 0, 26%);
$button-unelevated-disabled-background-color: rgba(0, 0, 0, 12%);
$list-matched-color: $base-light-green-color;
$list-success-color: $base-green-color;
$list-image-background-color: #F0F0F0;
$section-box-light-color: $tertiary-background-color;
$section-box-form-color: #FBFDE9;
$table-row-color: #F4F5F4;
$nutrient-bar-color: #EEB600;
$nutrient-bar-gradient-end-color: #FFFFFF;
$nutrient-bar-background-color: #CCCCCC;
$separator-pipe-color: #EEF0EE;
$disabled-color: #D8D8D8;
$disabled-selected-color: $secondary-light-color;
$form-field-underline-color: rgba(0, 0, 0, 42%);
