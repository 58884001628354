@use "utils/colors";
@use "utils/shared-variables";

/* stylelint-disable */

/* Override material dialog component */

.cdk-overlay-container {
	.cdk-overlay-dark-backdrop {
		background: colors.$tertiary-background-color;
		opacity: 0.5;
	}

	.cdk-global-overlay-wrapper {
		.publishing-problems-modal {
			min-width: 750px;
		}
	}
}

.mat-mdc-dialog-surface {
	padding: 24px;
	background: colors.$tertiary-background-color;
	overflow: visible !important;
	color: colors.$base-grey-color;

	.mat-mdc-dialog-title.mdc-dialog__title {
		font-family: shared-variables.$secondary-font;
		font-weight: normal;
		word-wrap: break-word;
		max-height: 300px;
		overflow: auto;
		margin: 0 0 12px;
		line-height: 1.8;
		padding: 0;
		letter-spacing: 0.25px;
		color: colors.$base-grey-color;

		&::before {
			display: none;
		}
	}

	.mat-dialog-subtitle {
		color: colors.$base-grey-color;
		line-height: 1.5;
		padding: 0 0 12px;
		margin: 0;
	}

	.mat-mdc-dialog-content.mdc-dialog__content {
		--mdc-dialog-supporting-text-tracking: normal;
		--mdc-dialog-supporting-text-color: $base-grey-color;

		padding: 0;
		font-size: 16px;
		line-height: 1.8;

		.dialog-content-item {
			padding: 12px 0;

			&--form-field {
				padding-bottom: 20px;
			}

			&:last-child {
				padding-bottom: 0;
			}
		}

		.error-subtitle {
			color: colors.$base-red-color;
			font-size: 14px;
			margin-top: 32px;
		}
	}

	.mat-mdc-dialog-actions {
		padding: 30px 0 0;
		border-top-width: 0;

		button:not(:last-child) {
			margin-right: 8px;
			margin-left: 0;
		}

		button:last-child {
			margin: 0;
		}
	}
}
