$navigation-sidebar-width: 240px;
$navigation-min-height: 224px;
$primary-font: 'NeueFrutigerWorld-Book';
$secondary-font: 'NeueFrutigerWorld';
$ternary-font: 'NeueFrutigerWorld-X';
$box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 20%);
$box-shadow-small: 0 0 6px 0 rgba(0, 0, 0, 20%);
$button-icon-margin: 8px;
$form-bottom-padding: 150px;
$browse-bottom-margin: 50px;
$text-button-horizontal-padding: 15px;
$content-list-image-size: 72px;
$content-list-image-size-small: 62px;
$section-margin: 16px;
$spacing: 24px;
$form-column-gap: $spacing * 2;
$dropdown-option-height: 42px;
