@use 'utils/colors';
@use 'utils/shared-variables';

.nutriu {
	.mat-mdc-menu-panel {
		background-color: colors.$color-light;
		border-radius: 0;
		max-width: inherit;

		box-shadow:
			0 2px 4px -1px rgba(0, 0, 0, 20%),
			0 4px 5px 0 rgba(0, 0, 0, 14%),
			0 1px 10px 0 rgba(0, 0, 0, 12%);

		.mat-mdc-menu-item {
			font-family: shared-variables.$primary-font;
			font-size: 16px;
			background: transparent;
			transition: background-color 0.2s;

			.mdc-list-item__primary-text,
			.mat-mdc-menu-item-text {
				display: flex;
				align-items: center;
				letter-spacing: normal;
				color: colors.$base-grey-color;
			}

			&:hover {
				background-color: colors.$base-beige-green-color;
			}
		}
	}

	.mat-mdc-menu-content {
		padding: 0;
	}
}
