@use 'utils/colors';
@use 'utils/shared-variables';

$time-column-width: 76px;

.nutriu {
	calendar {
		color: colors.$base-grey-color;
		font-family: shared-variables.$secondary-font;
		font-size: 12px;
		font-weight: normal;
	}

	.cal-header {
		font-size: 12px;
	}

	.cal-month-view {
		.cal-day-cell {
			display: block;

			&.cal-today {
				background-color: inherit;

				.cell-header {
					.cell-date {
						background-color: colors.$base-light-green-color;
						color: colors.$primary-dark-color;
						font-weight: bold;
					}
				}
			}
		}

		.cal-day-cell.cal-today .cell-date {
			background-color: colors.$base-green-color;
			color: colors.$color-light;
		}

		.cal-cell {
			width: calc(100% / 7);
			min-height: 106px;

			&:hover {
				cursor: pointer;
			}
		}

		.cal-days {
			border-left: none;
			border-right: none;

			.cal-cell {
				min-height: 106px;
			}

			:last-child {
				.cal-cell-row {
					border-bottom: none;
				}
			}
		}

		.cal-header {
			&:hover {
				background-color: transparent;
			}

			.cal-cell {
				cursor: default;
				padding: 16px 0;
				min-height: auto;

				&:hover {
					background-color: transparent;
				}
			}
		}

		.cal-days {
			.cal-weekend {
				background-color: colors.$lighter-grey-background-color;
			}
		}
	}

	.cal-week-view {
		border: none;

		.cal-day-headers {
			border: none;
			padding-left: $time-column-width;
			border-bottom: 1px solid colors.$base-grey-light-color;
			padding-bottom: 8px;

			.cal-header {
				border: none;
				color: colors.$base-grey-color;

				&:hover {
					background-color: inherit;
				}

				&.cal-weekend {
					opacity: 1;
					color: colors.$base-grey-color;

					span {
						color: colors.$base-grey-color;
					}
				}

				span {
					border-radius: 2px;
					color: colors.$primary-dark-color;
					display: block;
					font-size: 12px;
					height: 22px;
					margin: 0 auto;
					margin-top: 4px;
					opacity: 1;
					text-align: center;
					width: 22px;
				}

				&.cal-today {
					background-color: inherit;

					span {
						background-color: colors.$base-light-green-color;
						font-weight: bold;
					}
				}
			}
		}

		.cal-day-columns {
			height: 100%;
		}

		.cal-day-column {
			&:first-of-type,
			&:last-of-type {
				&:not(:only-of-type) {
					background-color: colors.$lighter-grey-background-color;
				}
			}

			.cal-hour {
				border-top-color: transparent;

				& + .cal-hour {
					border-top-color: colors.$base-grey-light-color;
				}
			}
		}

		.cal-hour {
			background-color: inherit;

			.cal-hour-segment {
				border-bottom: 1px solid colors.$base-grey-light-color;

				&:hover {
					cursor: pointer;
				}
			}

			&:last-of-type {
				.cal-hour-segment {
					border-bottom: none;
				}
			}
		}

		.cal-time-events {
			max-height: 640px;
			border-left: none;
			border-bottom: none;
			border-right: none;
			overflow: scroll;

			.cal-time {
				color: colors.$base-grey-middle-color;
				font-size: 12px;
				font-weight: normal;
				line-height: 18px;
				padding-right: 16px;
				padding-top: 8px;
				text-align: right;
				width: $time-column-width;
			}

			.cal-time-label-column {
				width: $time-column-width;

				.cal-hour-segment {
					&:hover {
						cursor: initial;
					}
				}

				.cal-hour {
					.cal-hour-segment {
						border-bottom: none;
						position: relative;
					}

					.cal-time {
						padding-top: 0;
						position: absolute;
						top: -10px;
						right: 0;
					}

					&:first-of-type {
						.cal-time {
							display: none;
						}
					}
				}
			}

			.cal-hour-odd {
				background-color: inherit;
			}

			.cal-event {
				padding: 0;
				border: none;
				border-radius: initial;
				background: none;

				.event {
					height: 100%;
					margin: 0;
				}
			}
		}
	}
}
