@use "utils/colors";

/* stylelint-disable */

.mdc-radio__outer-circle,
.mdc-radio__inner-circle {
  border-color: colors.$primary-dark-color !important;
}

.mat-mdc-radio-button {
  .mdc-radio {
    --mdc-radio-state-layer-size: 20px;
    --mat-mdc-radio-ripple-color: transparent;
    --mat-mdc-radio-checked-ripple-color: transparent;
  }

  .mdc-form-field > label {
    padding-left: 16px;
    cursor: pointer;
    letter-spacing: normal;
    color: colors.$secondary-color;
  }

  .mat-mdc-radio-touch-target {
    display: none;
  }

  &.nut-radio-button--padding-left-small .mdc-form-field label {
    padding-left: 8px;
  }

  &.nut-radio-button--align-start .mdc-form-field {
    align-items: flex-start;
  }


  &.nut-radio-button--with-multirow-label .radio-button-item-label {
    .subtitle {
      margin: 0;
      color: colors.$primary-dark-color;
    }

    .information {
      margin: 0 0 6px;
    }
  }
}
