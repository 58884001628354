/**
 * Copyright (C) 2021 - 2023 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

// While DAM is part of AUI, we are duplicating shared variables that we need here.
// This allows us a shared design without depending on non-DAM files.

// fonts
$primary-font: 'NeueFrutigerWorld-Book';
$secondary-font: 'NeueFrutigerWorld';
$ternary-font: 'NeueFrutigerWorld-X';

// colors
$base-green-color: #5D9621;
$base-grey-light-color: #E5E5E5;
$base-grey-middle-color: rgba(0, 0, 0, 54%);
$base-pale-color: #FCF4DB;
$color-white: #FFFFFF;
$color-black: #000000;
$color-red: #C11400;
$error-background-light-color: #F7E2E0;
$placeholder-grey: #F0F0F0;
