@use 'utils/colors';
@use "utils/shared-variables";

*,
*:before,
*:after {
	box-sizing: border-box;
}

/* stylelint-disable selector-no-type */
html,
body {
	height: 100%;
	min-height: 100%;
}

body {
	font-family: shared-variables.$primary-font;
	font-size: 14px;
	line-height: 22px;
	margin: 0;
	color: colors.$secondary-color;

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

/* stylelint-enable selector-no-type */

.main-container {
	background-color: colors.$secondary-background-color;
}

input:disabled {
	background: transparent;
}
