/* stylelint-disable */
@use 'utils/colors';

$snack-bar-background-color: colors.$base-grey-color;
$snack-bar-error-background-color: colors.$danger-background-light-color;
$snack-bar-error-color: colors.$base-red-color;

.mdc-snackbar.mat-mdc-snack-bar-container {
  margin: 24px;
  max-width: 33vw;

  .mdc-snackbar__surface {
    background-color: $snack-bar-background-color !important;
    min-width: 400px !important;
    box-sizing: border-box !important;
    height: auto !important;

    .mat-mdc-snack-bar-label {
      color: colors.$color-light;
      display: flex;
      justify-content: center;
    }

    .mdc-button.mat-mdc-snack-bar-action {
      color: colors.$color-light;

      &:active {
        color: colors.$primary-color;
      }
    }

    .mdc-button__ripple {
      display: none;
    }
  }

  &.snack-error .mdc-snackbar__surface {
    border: 1px solid $snack-bar-error-color;
    background-color: $snack-bar-error-background-color !important;

    .mat-mdc-snack-bar-label{
      color: $snack-bar-error-color;
    }
  }
}
