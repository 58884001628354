@use "utils/colors";

/* stylelint-disable */

.nut-checkbox.mat-mdc-checkbox {
	--mdc-checkbox-state-layer-size: 16px;
	pointer-events: none;

	.mdc-checkbox {
		padding: 0;
		width: var(--mdc-checkbox-state-layer-size, 16px);
		height: var(--mdc-checkbox-state-layer-size, 16px);

		.mdc-checkbox__checkmark {
			color: colors.$color-light;
		}

		.mdc-checkbox__mixedmark {
			border-color: colors.$color-light;
		}

		.mdc-checkbox__background {
			top: 0;
			left: 0;
			width: var(--mdc-checkbox-state-layer-size, 16px);
			height: var(--mdc-checkbox-state-layer-size, 16px);
			border-color: colors.$primary-dark-color !important;
		}
	}

	&.mat-mdc-checkbox-checked,
	.mdc-checkbox__native-control:indeterminate ~ {
		.mdc-checkbox__background {
			background-color: colors.$primary-dark-color !important;
		}

		.mdc-checkbox__mixedmark {
			background-color: colors.$tertiary-background-dark-color;
		}
	}

	&.mat-mdc-checkbox-disabled {
		.mdc-checkbox__background {
			border-color: colors.$disabled-color !important;
		}

		&.mat-mdc-checkbox-checked .mdc-checkbox__background,
		.mdc-checkbox__native-control:indeterminate ~ .mdc-checkbox__background {
			background-color: colors.$disabled-color !important;
		}
	}

	.mdc-checkbox-checkmark-path {
		stroke: colors.$tertiary-background-color;
	}

	.mdc-form-field {
		display: block;
		line-height: var(--mdc-checkbox-state-layer-size, 16px);
	}

	.mat-ripple,
	.mdc-checkbox__ripple,
	.mat-mdc-checkbox-touch-target {
		display: none;
	}

	.highlighted {
		color: colors.$secondary-color;
		background: transparent;
	}

	.selected {
		color: colors.$secondary-color;
		background: transparent;
	}

	&.nut-checkbox--size-medium {
		--mdc-checkbox-state-layer-size: 18px;
	}

	&.nut-checkbox--size-big {
		--mdc-checkbox-state-layer-size: 20px;
	}
}
