/* stylelint-disable */

@use 'utils/shared-variables';
@use "utils/colors";

.nutriu {
	a.button,
	button.button,
	button.mat-button, // TODO(mdc-migration): Remove this line once mat-button is migrated
	button.mdc-button {
		min-width: 125px;
		border: 1px solid colors.$button-primary-color;
		border-radius: 2px;
		box-shadow: none;
		background-color: colors.$button-primary-color;
		color: colors.$color-light;
		transition: all 0.2s;
		letter-spacing: normal;
		height: 38px;

		&:hover,
		&:focus {
			background-color: colors.$button-primary-hover-color;
			color: colors.$color-light;
			box-shadow: none;
		}

		&:active {
			background: colors.$primary-light-color;
			color: colors.$button-primary-color;
		}

		&[disabled] {
			background-color: colors.$primary-color;
			color: colors.$color-light;
			opacity: 0.4;
		}

		&--spacious {
			padding-left: 16px;
			padding-right: 16px;
		}

		&--action {
			min-width: 109px;
		}

		&--unelevated {
			color: colors.$button-unelevated-color;
			background-color: colors.$base-white-color;
			border: 1px solid colors.$base-white-color;

			&:hover,
			&:focus {
				color: colors.$button-unelevated-color;
				background-color: colors.$base-white-color;
			}

			&:hover .mat-mdc-button-persistent-ripple::before {
				opacity: 0;
			}

			&:disabled {
				color: colors.$button-unelevated-disabled-color;
				background: colors.$button-unelevated-disabled-background-color;
			}
		}

		&--inverse {
			background-color: colors.$tertiary-background-color;
			border: 1px solid colors.$button-primary-color;
			color: colors.$primary-color;
			box-shadow: none;

			&:hover,
			&:focus {
				background-color: colors.$tertiary-background-color;
				color: colors.$primary-dark-color;
				stroke: 1.5;
				border: 1px solid colors.$primary-dark-color;
			}

			&:active {
				background-color: colors.$base-beige-green-color;
				border: 1px solid colors.$button-primary-color;
				color: colors.$primary-color;
			}

			&[disabled] {
				background-color: colors.$tertiary-background-color;
				color: colors.$ternary-light-color;
				border-color: colors.$disabled-color;
				opacity: 1;
			}
		}

		&--warn-inverse {
			background-color: colors.$tertiary-background-color;
			border: 1px solid colors.$base-red-color;
			color: colors.$text-color-error;
			box-shadow: none;

			&:hover,
			&:focus {
				background-color: colors.$tertiary-background-color;
				stroke: 1.5;
				color: colors.$warning-background-dark-color;
				border: 1px solid colors.$warning-background-dark-color;
			}

			&:active {
				background-color: colors.$warning-background-light-color;
				color: colors.$text-color-error;
			}

			&[disabled] {
				background-color: colors.$tertiary-background-color;
				color: colors.$ternary-light-color;
				border-color: colors.$disabled-color;
				opacity: 1;
			}
		}

		&--warn {
			background-color: colors.$warning-background-color;
			border: 1px solid colors.$warning-background-color;
			border-radius: 2px;
			color: colors.$color-light;
			box-shadow: none;

			&:hover,
			&:focus {
				background-color: colors.$button-warning-hover-color;
				color: colors.$text-color-lighter;
			}

			&:active {
				background-color: colors.$warning-background-light-color;
				color: colors.$text-color-error;
			}

			&[disabled] {
				background-color: colors.$warning-background-light-color;
				border-color: colors.$warning-background-light-color;
				color: colors.$text-color-lighter;
				opacity: 1;
			}
		}

		&--basic {
			background-color: colors.$base-blue-color;
			border: 1px solid colors.$base-blue-color;
			border-radius: 2px;
			color: colors.$color-light;
			box-shadow: none;

			&:hover,
			&:focus {
				background-color: colors.$base-dark-blue-color;
				border-color: colors.$base-dark-blue-color;
				color: colors.$color-light;
			}

			&:active {
				background-color: colors.$base-darker-blue-color;
				border-color: colors.$base-darker-blue-color;
				color: colors.$color-light;
			}

			&[disabled] {
				background-color: colors.$base-grey-light-color;
				border-color: colors.$base-grey-light-color;
				color: colors.$base-grey-middle-color;
				opacity: 1;
			}
		}

		&--small {
			min-width: auto;
		}

		&--large {
			min-width: 400px;
		}

		// TODO(mdc-migration): Remove this line once mat-button is migrated
		.mat-button-wrapper {
			font-weight: normal;
		}

		&.button--dynamic {
			min-width: auto;
		}
	}

	.mdc-button.text-button-dropdown,
	.mdc-button.solid-button-dropdown {
		.mdc-button__label {
			display: inline-flex;
			align-items: center;
		}
	}

	.text-button,
	.mat-button.text-button,
	.mat-mdc-button.text-button {
		display: inline-flex;
		align-items: center;
		color: colors.$button-primary-color;
		font-family: shared-variables.$secondary-font;
		font-weight: normal;
		cursor: pointer;
		margin-right: 0;
		border: none;
		background-color: transparent;
		box-shadow: none;
		line-height: 1;

		.mdc-button__label {
			display: inline-flex;
			align-items: center;
		}

		// TODO(mdc-migration): Remove this line once mat-button is migrated
		.mat-button-focus-overlay {
			background-color: transparent;
		}

		&:hover,
		&:focus {
			background-color: transparent;
			color: colors.$button-primary-hover-color;
			text-decoration: underline;
		}

		&:active {
			background-color: transparent;
		}

		&[disabled] {
			background-color: transparent;
			color: colors.$ternary-light-color;
			opacity: 0.3;
			text-decoration: none;
		}

		// TODO(mdc-migration): Remove this line once mat-button is migrated
		.mat-button-ripple.mat-ripple {
			display: none;
		}

		.mdc-button__ripple,
		.mat-mdc-button-ripple {
			display: none;
		}

		&.icon-button {
			font-weight: normal;
			text-decoration: none;
			background-color: transparent;
			border: none;

			&.mat-raised-button {
				padding-left: 16px;
			}

			&:hover,
			&:focus {
				background-color: colors.$base-beige-green-color;
			}

			&:active {
				background-color: colors.$base-light-green-color;
				border: none;
			}
		}

		&--white {
			background-color: colors.$color-light;
			color: colors.$button-primary-color;
			font-family: shared-variables.$secondary-font;
			font-weight: normal;
			border: none;

			&:hover,
			&:focus {
				background-color: colors.$base-grey-lighter-color;
				text-decoration: underline;
				color: colors.$button-primary-color;
			}

			&:active {
				background-color: colors.$color-light;
				text-decoration: underline;
			}

			&.icon-button {
				&:hover,
				&:focus {
					background-color: colors.$base-grey-lighter-color;
					text-decoration: none;
					color: colors.$button-primary-hover-color;

					img {
						filter: brightness(0.9);
					}
				}

				&:active {
					background-color: colors.$base-light-green-color;
					text-decoration: none;
					border: 1px solid transparent;
					border-radius: 2px;
				}
			}
		}

		&.delete-button {
			color: colors.$text-color-error;
		}
	}

	.icon-button,
	.mat-button.icon-button {
		border: 1px solid transparent;
		border-radius: 2px;
		background-color: colors.$color-light;

		&.mat-raised-button {
			padding-left: 6px;
		}

		img {
			margin-right: shared-variables.$button-icon-margin;
		}

		&:hover,
		&:focus {
			background-color: colors.$color-light;
			cursor: pointer;

			img {
				filter: brightness(0.85);
			}
		}

		&:active {
			border: solid colors.$base-green-color 1px;
			border-radius: 2px;
			background-color: colors.$base-light-green-color;
		}

		&[disabled] {
			color: colors.$base-grey-middle-color;
			opacity: 1;
		}
	}

	.mat-button,
	.mat-raised-button.button {
		.mat-button-focus-overlay {
			opacity: 0;
		}

		&:hover {
			.mat-button-focus-overlay {
				opacity: 0;
			}
		}
	}

	.button-icon-padded {
		margin-right: 18px;
	}

	.button.icon-top-button {
		align-items: center;
		background-color: transparent;
		border-color: transparent;
		box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 20%);
		color: colors.$base-grey-color;
		display: flex;
		flex-direction: column;
		font-size: 12px;
		height: 68px;
		justify-content: center;
		line-height: 18px;
		min-width: 80px;
		padding: 4px;
		width: auto;

		img {
			margin-bottom: 6px;
		}

		&:disabled {
			background-color: transparent;
			color: colors.$text-color-warning;

			&:hover {
				box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 20%);
			}
		}

		&:hover {
			color: colors.$base-grey-color;
			cursor: pointer;
			background-color: transparent;
			box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 20%);

			&:disabled {
				cursor: default;
			}
		}
	}
}
