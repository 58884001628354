/* You can add global styles to this file, and also import other style files */

@use 'utils/dam-shared-variables';

.dam-header {
	display: flex;
	justify-content: space-between;
	position: sticky;
	top: 0;
	z-index: 2;
	background: dam-shared-variables.$color-white;
	padding: 36px 40px 32px;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 20%);

	h1 {
		font-family: dam-shared-variables.$ternary-font, sans-serif;
		font-size: 36px;
		line-height: 32px;
		margin: 0;
		color: dam-shared-variables.$base-green-color;
	}

	&--short {
		padding: 16px 30px;
		align-items: center;

		h1 {
			font-family: dam-shared-variables.$primary-font, sans-serif;
			font-size: 20px;
			font-weight: normal;
			color: dam-shared-variables.$color-black;
		}
	}
}

.dam-dialog {
	&-title {
		margin-top: 0;
	}

	&-description {
		margin-bottom: 20px;
	}

	&-actions {
		display: flex;
		justify-content: space-between;
	}
}
