@use 'utils/z-indexes';
@use 'utils/shared-variables';
@use 'utils/colors';

$fixed-bar-height: 72px;

.fixed-bar {
	position: sticky;
	width: 100%;
	right: 0;
	z-index: z-indexes.$fixed-navigation;
	display: flex;
	align-items: center;
	background-color: colors.$tertiary-background-color;
	padding: 0 30px;
	justify-content: space-between;
	height: $fixed-bar-height;
	box-shadow: shared-variables.$box-shadow-small;

	&.fixed-bar--auto-height {
		height: auto;

		.fixed-bar-section {
			&--right {
				margin-left: auto;
			}

			.button,
			.text-button {
				margin: 16px auto;

				&:not(:last-of-type) {
					margin-right: 8px;
				}
			}
		}
	}

	&--top {
		top: 0;
	}

	&--bottom {
		bottom: 0;
	}

	&--side-spacing {
		padding: 0 32px;

		.button,
		.text-button {
			margin-top: 18px !important; /* stylelint-disable-line */
			margin-bottom: 18px !important; /* stylelint-disable-line */
		}
	}

	& &__title {
		font-size: 20px;
		font-weight: normal;
		color: colors.$primary-dark-color;
		margin: 0;
	}

	.header-actions {
		display: flex;
		flex-direction: row;
		align-items: center;

		deep-link-generator {
			margin-right: 8px;
		}
	}

	&.fixed-bar__container {
		display: block;
		padding: 0;

		.fixed-bar__row {
			display: flex;
			padding: 16px 30px;

			.fixed-bar__title {
				display: flex;
				flex: 1;
				align-items: center;
			}

			&.no-padding {
				padding: 0;
			}
		}
	}
}

.container-for-fixed-bar {
	margin-top: 16px;
}
