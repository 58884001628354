.mat-sidenav.mat-drawer-side {
	border: none;
}

.mat-drawer-backdrop.mat-drawer-shown {
	display: none;
}

.mat-drawer {
	box-shadow: none;
}
