/* stylelint-disable */
@use 'utils/colors';

.mat-mdc-tab-group  {
  .mat-mdc-tab-header {
    .mdc-tab__text-label {
      letter-spacing: normal;
      color: colors.$base-grey-color;

      a {
        color: inherit;
        text-decoration: none;
      }
    }

    .mdc-tab--active .mdc-tab__text-label {
      font-weight: bold;
      color: colors.$primary-color;
    }

    .mdc-tab-indicator__content {
      transition: .2s;
      border-top-width: 4px;
    }

    .mat-mdc-tab {
      min-width: 0;

      .mdc-tab__ripple::before {
        opacity: 0;
      }
    }

    .mat-ripple-element {
      background: rgba(0, 0, 0, 1);
    }

    &-pagination-chevron {
      border-color: colors.$primary-color;
    }

    &-pagination-disabled .mat-mdc-tab-header-pagination-chevron {
      opacity: 1;
      border-color: rgba(0, 0, 0, 0.38);
    }

    &-pagination-after:not(&-pagination-disabled) {
      box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    }

    &-pagination-before:not(&-pagination-disabled) {
      box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    }
  }

  &.nut-tab-group--with-underline .mat-mdc-tab-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }

  &.nut-tab-group--with-min-tab-width .mat-mdc-tab {
    min-width: 160px;
  }

  &.nut-tab-group--with-hover-effect .mdc-tab:hover {
    .mdc-tab__text-label {
      font-weight: bold;
      color: colors.$primary-color;
    }

    .mdc-tab-indicator__content {
      opacity: 1;
    }
  }

  &.nut-tab-group--with-margin-bottom .mat-mdc-tab-body-wrapper {
    margin-top: 16px;
  }
}
