@use 'utils/shared-variables';
@use 'utils/colors';

shared-variables.$form-column-gap: shared-variables.$spacing * 2;

@mixin form-column($n) {
	&-#{$n} {
		flex: #{$n};
	}
}

.form-grid {
	display: flex;
	flex-wrap: wrap;
	column-gap: shared-variables.$form-column-gap;

	&--separated {
		margin-bottom: shared-variables.$spacing;
	}

	.form-row {
		margin-bottom: 32px;
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
		gap: shared-variables.$form-column-gap;
		flex-grow: 1;
	}

	.form-column {
		flex-basis: calc((100% - #{shared-variables.$form-column-gap}) / 2);

		@for $i from 1 through 12 {
			@include form-column($i);
		}

		&--full {
			flex: 0 0 100%;
		}

		&.hidden {
			display: none;
		}
	}

	.form-field-box {
		display: block;
		margin: 16px 0;

		&--halved {
			margin: 8px 0;
		}

		&--doubled {
			margin: {
				top: 32px;
				bottom: 32px;
			}
		}

		&--tag-multiselect {
			margin-top: 8px;
		}
	}
}

.form-name {
	font-family: shared-variables.$secondary-font;
	font-size: 12px;
	font-weight: bold;
	color: colors.$primary-light-color;
	text-transform: uppercase;
	margin: 0;
	padding: 20px 0 16px;

	&--dark {
		color: colors.$primary-dark-color;
		font-weight: 500;
	}
}

.form-group {
	display: flex;
	column-gap: shared-variables.$form-column-gap;
	flex-grow: 1;
	flex-wrap: wrap;
	align-items: center;

	.form-group-item {
		flex: 1;
	}
}

.form-actions {
	text-align: right;

	button.button,
	button.mat-button,
	button.text-button {
		&:not(:last-of-type) {
			margin-right: shared-variables.$spacing;
		}
	}
}
